
import axios  from "axios"
 export default {
    data() {
        return {
            canDeleteMinuta: false,
        }
    },
    created() {
        this.canDeleteMinuta = this.hasPermissions("delete-minutas" , (permision) => {this.canDeleteMinuta = permision});
    },
    methods: {
        hasRole(role, callback){
            axios({
                method: "GET",
                url: "/api/users/hasRole",
                params:{    
                    name: role
                }
            }).then(response => {
                callback(response.data.hasRole);
            }).catch(() => {
                return false;
            })
        
        },
        hasPermissions(permission, callback) {
            axios({
                method: "GET",
                url: "/api/users/hasPermission",
                params:{    
                    name: permission
                }
            }).then(response => {
                callback(response.data.hasPermission)
            }).catch(() => {
                return false;
            })
        }
    }
}