<template>
  <div>
    <section class="dashboard">
      <div class="row">
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">
              <h3 class="card-title">Factura {{ $route.params.id }}</h3>
              <form action="">
                <div class="row">
                  <div class="col-6 grid-margin">
                    <div class="form-group">
                      <label for="">Seccion - Mesa</label>
                      <input
                        type="text"
                        :value="mesa + ' - ' + sala"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-6 grid-margin">
                    <div class="form-group">
                      <label for="">Fecha</label>
                      <input
                        type="text"
                        :value="dateTransact"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 grid-margin">
                    <div class="form-group">
                      <label for="">Total Neto</label>
                      <input
                        type="text"
                        :value="numeral(totalNeto).format('$ 0,0.00')"
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-6 grid-margin">
                    <div class="form-group">
                      <label for="">Total</label>
                      <input
                        type="text"
                        class="form-control"
                        :value="numeral(total).format('$ 0,0.00')"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </form>
              <div class="center">
                <h4>Productos</h4>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Cantidad</th>
                      <th>Vendedor</th>
                      <th>Fecha</th>
                      <th>Precio</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in items" :key="item.id">
                      <td>{{ item.descripcion }}</td>
                      <td>{{ item.cantidad }}</td>
                      <td>{{ item.vendedor }}</td>
                      <td>
                        {{ moment(item.fecha).format("DD/MM/YYYY hh:mm A") }}
                      </td>
                      <td>{{ numeral(item.monto).format("$ 0,0.00") }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="form-group" v-if="canDeleteMinuta">
                  <button
                    type="button"
                    @click="deleteMinuta"
                    class="btn btn-danger"
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import numeral from "numeral";
import axios from "axios";
import moment from "moment";
import  acl  from '@/mixins/acl.js'

export default {
  name: "open-table-details",
  mixins: [acl],
  data() {
    return {
      items: [],
      dateTransact: "",
      total: 0,
      totalNeto: 0,
    };
  },
  methods: {
    numeral: numeral,
    moment,
    getTransaction() {
      axios("/api/reports/transacts/open/detail", {
        params: {
          mesa: this.mesa,
          sala: this.sala,
          cliente: this.cliente,
          numero: this.numero,
        },
      }).then((res) => {
        this.items = res.data.data.products;
        this.dateTransact = moment(res.data.data.fecha).format(
          "DD/MM/YYYY hh:mm A"
        );
        this.total = res.data.data.totalbruto;
        this.totalNeto = res.data.data.totalneto;
    });
    },
    deleteMinuta() {
      axios("/api/reports/transacts/open/delete", {
        method: 'DELETE',
        params: {
          mesa: this.mesa,
          sala: this.sala,
          cliente: this.cliente,
          numero: this.numero,
        },
      }).then(() => {
        this.$router.go(-1)
      });
    },
  },
  created() {
    this.getTransaction();
    this.$store.dispatch("setEvent", { name: "open_openTables_details" });
  },
  computed: {
    mesa: function () {
      return this.$route.params.id.split("|")[0];
    },
    sala: function () {
      return this.$route.params.id.split("|")[1];
    },
    cliente: function () {
      return this.$route.params.id.split("|")[2];
    },
    numero: function () {
      return this.$route.params.id.split("|")[3];
    },
  },
};
</script>